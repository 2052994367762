import React from 'react';

import { Layout } from '../components';
import { address } from '../../data.yaml';

const NotFoundPage = () => (
  <Layout address={address} title="404: Not found">
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist...</p>
  </Layout>
);

export default NotFoundPage;
